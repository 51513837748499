/* page header */
.department-header {
    text-align: left;
    width: 912px;
    font-family: var(--font);
    font-size: 32px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.88em;
    letter-spacing: 0.17px;
    color: #000;
    margin-bottom: 0;
}

/* add department container */
.button-container {
    width: 912px;
}

.departments #adddepartment-btn{
    color: var(--green);
    font-weight: bold;
    font-family: var(--font);
    font-size: 14px;
    background-color:  white; 
    border-color: white;
    box-shadow: none;
}

/* pop up */
.departments-container .modal-dialog {
    margin-top: 270px;
    min-width: 928px; 
}


.department-popup {
    margin: 0 auto;
}

.department-popup .popup-header {
    margin-left: 28px;
}

.departments #adddepartment-btn{
    color: var(--green);
    font-weight: bold;
    font-family: var(--font);
    background-color:  white;
    border-color: white;
    box-shadow: none;
}

.department-modal-buttons #submit-btn{
    color: black;
    font-weight: bold;
    font-size: 14px;
    font-weight: bold;
    font-family: var(--font);
    text-align: left;
    text-transform: uppercase;
    line-height: 20px;
    letter-spacing: 2.3px;
    background-color:  var(--green); 
    border-color: var(--green);
    box-shadow: none;

    width: 105px;
    height: 52px;
    margin: 32px;
    padding: 16px 17.5px;
    border-radius: 4px;
}

.department-modal-buttons #cancel-btn{
    color: black;
    font-weight: bold;
    font-size: 14px;
    font-weight: bold;
    font-family: var(--font);
    text-align: left;
    text-transform: uppercase;
    line-height: 20px;
    letter-spacing: 2.3px;
    background-color:  white; 
    border-color: white;
    box-shadow: none;

    width: 105px;
    height: 52px;
    margin: 32px;
    padding: 16px 17.5px;
    border-radius: 4px;
} 

.textfield label {
    font-size: 14px;
}

.textfield-department {
    min-width: 864px;
}

/* table */
#department-table .table-headers {
    font-family: var(--font);
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: 2.3px;
    text-transform: uppercase;
    color: #000;
}

#department-table tr:not(:first-child) {
    border-bottom: solid 1px var(--light-grey2);
}

#department-table {
    width: 928px;
    margin-top: 10px;

    text-align: left;
}

#department-table td {
    width: 360px;
}

#department-table tr{
    width: 928px;
    height: 56px;
}

#department-table tr:not(:first-child):hover {
    background-color: rgba(108, 199, 43, 0.1);;
}

/* general */
.margin {
    margin-left: 16px;
}

.padding-left {
    padding-left: 16px;
}

/* form control */
.error-msg {
    width: 100%;
    margin-left: 50px;

    text-align: left;
    font-size: 14px;
}