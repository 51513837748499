:root {
  --summary-heading-color: #000;
  --cell-margin: 16px;
}

.client-summary {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  margin-bottom: 45px;
}

.client-summary > .heading {
  margin-left: var(--cell-margin);
  margin-right: var(--cell-margin);
  margin-bottom: 0px;
  font-family: var(--font);
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: 0.21px;
  color: var(--summary-heading-color);
}

.client-summary > .subheading {
  margin-top: 8px;
  margin-left: var(--cell-margin);
  margin-right: var(--cell-margin);
  margin-bottom: 23px;
  font-family: var(--font);
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: 2.3px;
  color: var(--summary-heading-color);
}

.time-entry-cell {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  border-bottom: solid 1px var(--summary-border-color);
  width: 100%;
  margin-top: 16px;
}