:root {
  --font: 'Gotham A';
  --font-color: #000;
  --overlay-color: #000;
  --overlay-color-current: #fff;
}

.calendar-day-container {
  position: relative;
  font-family: var(--font);
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 40px;
  height: 48px;
  margin: 0;
  border-radius: 4px;
  user-select: none;
}

.calendar-day-container > span {
  font-family: var(--font);
  width: 17px;
  height: 16px;
  margin: 6px 0 6px;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: var(--font-color);
}

.calendar-day-overlay {
  width: 40px;
  height: 48px;
  opacity: 0.1;
  background-color: var(--overlay-color);
  position: absolute;
  border-radius: 4px;
}

.calendar-day-container:hover, .calender-day-overlay:hover {
  background-color: var(--overlay-color-current);
}

.not-required {
  opacity: 0.4;
}