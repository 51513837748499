@import './fonts/fonts.css';

:root {
  --font: "Gotham A";
  --green: #6dc72c;
  --bright-green: #6aff00;
  --green-opacity3: rgba(108, 199, 43, 0.3);
  --light-grey: #e3e3e3;
  --dark-grey: #23282D;
  --dg-font: #41464A;
  --lg-font: #9a9a9a;
  --light-grey2: #f2f2f2;
  --light-grey3: #c6c6c6;
  --light-grey4: #bababa;
  --off-white: #fafafa;
  --dark-grey2: #dbdbdb;
  --dark-grey3: #a8a8a8;
  --red: #e02021;
}

body { 
  margin: 0;
  font-family: var(--font);
}

.App {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  text-align: center;
}

.links {
  text-decoration: none;
  font-weight: bolder;
  color: black;
}

.links:hover {
  color: var(--green);
}

.error-msg{
  margin-top: 1em;
  font-size: 10px;
  color: var(--red);
}

.hide {
  display: none;
}

/* headers */
.section-header {
  font-family: var(--font);
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67em;
  letter-spacing: 0.21px;
  color: #000;

}

.page-header {
  text-align: left;
  width: 912px;

  font-family: var(--font);
  font-size: 32px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.88em;
  letter-spacing: 0.17px;
  color: #000;
}

.popup-header {
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67em;
  letter-spacing: 0.21px;
  color: #000;
  text-align: left;
  margin-left: 16px;
  margin-top: 24px;
}

.component-header{
  font-size: 14px;
  font-weight: bold;
  font-family: var(--font);

  text-align: left;
  text-transform: uppercase ;

  line-height: 20px;
  letter-spacing: 2.3px;

  margin: 11px 0px 5px 10px;
}

.disabled-header {
  color: var(--dark-grey3)
}

.modal-dialog .modal-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

/* fixed widths */
.width-112{
  width: 112px;
}

.width-128{
  width: 128px;
}

.width-200{
  width: 200px;
}

.width-250{
  width: 250px;
}

.width-300{
  width: 300px;
}

.width-320{
  width: 320px;
}

.width-350{
  width: 350px;
}

.width-408{
  width: 390px;
}

.width-450{
  width: 448px;
}

.width-440{
  width: 440px;
}

.width-550{
  width: 550px;
}

.width-610{
  width: 608px;
}

.width-928{
  width: 928px;
}

.width-1024{
  width:1024px;
}

@media (min-resolution: 969dpi) {
  .App {
    max-width: 1024px;
  }
}
