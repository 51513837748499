.timepg{
    width: 100%;
}

.time-pg-popup .modal-dialog {
    min-width: 928px; 
}

.time-pg-popup .modal-dialog .modal-content {
    margin-top: 270px;
    width: 928px; 
}

.timepg .left-div{
    width: 300px;
    height: 320px;
    margin-top: 14px;
    background-color: green;
}

.timepg .today-container{
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;

    margin: 14px;
}

.timepg .right-div > div{
    margin: 0 48px;
}

.timepg .right-div {
    margin-bottom: 33px;
}

.timepg .right-div .text-field-container .width{
    margin-right: 0;
}

.timepg .right-div .time-entry-container{
    margin-right: 32px;
}

.timepg .right-div .today-container .today{
    text-align: center;
    font-family: var(--font);
    font-size: 32px;
    font-weight: 900;
}

.timepg .right-div .timeentry-container{
    width: 350;
}

.timepg .right-div .text-field-container{
    margin-top: 27px;
}

.timepg .right-div .add-btn{
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    vertical-align: center;

    margin-top: 24px;
}

.timepg .client-summary .time-entry-cell {
    margin-top: 0;
    padding: 15px 5px 5px 5px;
}

.timepg .client-summary .time-entry-cell:hover {
    background: var(--green-opacity3);
}

@media (min-width: 969px) and (max-width: 1080px) {
    .timepg .left-div{
        width: 260px;
        height: 280px;
    }
}

@media (max-width: 969px) {
    .right-div > div{
        margin: 0 14px;
    }

    .timepg .left-div{
        width: 260px;
        height: 280px;
    }

    .right-div .today-container{
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .right-div .today-container .today{
        text-align: center;
        font-size: 18px;
    }

    .flex-column .right-div .time-entry-container{
        margin-right: 0;
    }
}