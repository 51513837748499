.loginpg{
    margin: 4em;
}

.drops-logo{
    text-decoration: none;
    font-family: var(--font);
    font-weight: 800;
    font-size: 1.75em;

    margin: 0 0 0 10px;
}