.report-table-wrapper {
  width: 100%;
  max-width: 1320px;
  margin-left: auto;
  margin-right: auto;
}

.report-subheading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  width: 100%;
  max-width: 1320px;
}

#csv-export-btn {
  color: var(--green);
  font-weight: bold;
  font-family: var(--font);
  font-size: 14px;
  background-color: white;
  border-color: white;
  box-shadow: none;
  line-height: 1.43;
  letter-spacing: 2.3px;
  margin-left: 0px;
}
.table-footer #csv-export-btn {
  padding-left: 0px;
}

.table-footer {
  border-bottom: white;
}

.requirements-text,
.report-table-header > th {
  font-size: 14px;
  font-weight: bold;
  line-height: 1.43;
  letter-spacing: 2.3px;
}
.requirements-text {
  display: flex;
  text-align: right;
  color: #da4242;
  padding-right: 1rem;
  align-self: center;
  margin-bottom: 0px;

}
.report-table-header > th {
  padding-bottom: 1.25rem;
}

.report-table {
  margin-top: 1.5rem;
  max-width: 1320px;
}

.report-table td {
  padding: 0.75rem 0.5rem;
  font-size: 18px;
  line-height: 1.78;
  letter-spacing: 0.25px;
}

.report-table .date-header {
  padding-left: 0.5rem;
}

.table-cell-description {
  max-width: 700px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.total-hours {
  font-size: 18px;
  font-weight: 900;
  line-height: 1.78;
  letter-spacing: 0.25px;
  color: #000;
}

.sort-arrow::before{
  color: #d9d9d9;
}
.sort-arrow--selected::before{
  color: #000;
}
.sort-arrow--selected--descending::before{
  color: #000;
  transform: rotate(180deg);  
}

.report-row--unconfirmed {
  color: red;
}