.settings .page-header {
    font-size: 32px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.88em;
    letter-spacing: 0.17px;
    color: #000;
    text-align: left;
    width: 928px;

    margin-bottom: 0;
}

.settings .section-header {
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.67em;
    letter-spacing: 0.21px;
    color: #000;
    text-align: left;
    margin-top: 45px;
    margin-left: 16px;
}

.settings .header {
    margin-top: 0;
}

.settings .logout-button {
    width: 928px;
    margin-bottom: 38px;
}

.settings > div {
    width: 928px
}

.settings .notifications {
    margin-bottom: 24px;
}

.settings #add-notification-btn {
    color: var(--green);
    font-weight: bold;
    font-family: var(--font);
    font-size: 14px;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43em;
    letter-spacing: 2.3px;
    background-color:  white; /* new color goes her */
    border-color: white;
    box-shadow: none;
}

.settings .dropdown-time-zone, .dropdown-appearence {
    width: 448px;
    margin-bottom: 27px;
}

.settings .dropdown-format {
    width: 386px;
    transition: all .5s ease;
    -moz-transition: all 2s; /* Firefox 4 */
    -webkit-transition: all 2s; /* Safari and Chrome */
    -o-transition: all 2s; /* Opera */
    position: relative;
}

.settings .dropdown-format-sm {
    width: 328px;
    transition: all .5s ease;
    -moz-transition: all 2s; /* Firefox 4 */
    -webkit-transition: all 2s; /* Safari and Chrome */
    -o-transition: all 2s; /* Opera */
    position: relative;
}

.settings .dropdown-day {
    width: 288px;
}

.phone-number {
    text-align: left;
}

.phone-number input {
    width: 928px
}

.phone-number-info {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.86;
    letter-spacing: 0.19px;
    color: #000;
    margin-left: 14px
}

.delete-png {
    width: 24px;
    height: 24px;

    transition: all .5s ease;
    -moz-transition: all 2s; /* Firefox 4 */
    -webkit-transition: all 2s; /* Safari and Chrome */
    -o-transition: all 2s; /* Opera */
    position: relative;
}