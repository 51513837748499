/* 
    button div which contains 
    "Add Employees" and "Former Employees" buttons 
*/
.employees .button-container {
    width: 912px;
}

.employees #addEmployee-btn{
    color: var(--green);
    font-weight: bold;
    font-family: var(--font);
    background-color:  white; 
    border-color: white;
    box-shadow: none;
}

.employees #formerEmployees {
    color: var(--green);
    font-weight: bold;
    font-family: var(--font);
    background-color:  white; 
    border-color: white;
    box-shadow: none;
}

/* container for all individual employees */
.employees-container {
    width: 912px;
}

.employee {
    margin-top: 45px;
}


/* Pop Up */
.employee-popup .modal-dialog {
    min-width: 928px; 
}

.employee-popup .modal-dialog .modal-content {
    min-width: 928px; 
    max-width: 1024px;
}


/* search bar*/
.employees .searchbar-container { /* container */
    width: 928px;

    padding: 5px 16px;
    border: 1px solid var(--light-grey2);
    border-radius: 4px;
    background-color: var(--light-grey2);
}

.employees .active { /* active container */
    border-color: var(--green);
}

.employees .input-searchbar { /* input search bar */
    border: none;
    background-color: var(--light-grey2);
    
    max-width: 875px;

    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.78em;
    letter-spacing: 0.25px;
    color: rgba(0, 0, 0, 0.5);
}

.employees .input-searchbar:focus-visible { /* remove default styles */
    outline: none;
}

/* table */
.employees .table-headers {
    font-family: var(--font);
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: 2.3px;
    text-transform: uppercase;
    color: #000;
}

.employees tr:not(:first-child) {
    border-bottom: solid 1px var(--light-grey2);
}

.employees table {
    width: 928px;
    margin-top: 53px;
}

.employees table tr {
    width: 928px;
    height: 56px;
}

.employees .padding-left {
    padding-left: 16px;
}



.employees table tr:hover {
    background-color: rgba(108, 199, 43, 0.1);;
}

/* general */
.employees .margin {
    margin-left: 16px;
}