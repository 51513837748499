/* General */
.add-employee-input-container {
    margin: 14px 0;
}

.add-employee-input-width {
    width: 413px;
    height: 52px;
}

.add-employee-input-sm-width {
    width: 285px;
    height: 52px;
}

.add-employee-input-lg-width {
    width: 864px;
    height: 52px;
}

.col-A, .col-B {
    width: 425px
}

.col-B {
    margin-left: 25px;
}

.margin-left-xl {
    margin-left: 48px;
}

.margin-left-lg {
    margin-left: 39px;
}

.margin-left-sm {
    margin-left: 16px;
}

/* headers */
.add-employee-container .dropdown-timezone .header {
    margin-top: 0;
    margin-bottom: 14px;
}

.permissions .header {
    margin-top: 0;
}

/* Add Employee PopUp Container */
.add-employee-container {
    margin:  0 14px 14px 14px;
}

.add-employee-container .header-main {
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.67em;
    letter-spacing: 0.21px;
    color: #000;
    text-align: left;
    margin-left: 16px;
    margin-top: 24px;
}

/* PopUp Buttons */
.employee-modal-buttons {
    margin-top: 35px;
    margin-bottom: 32px;
}

.employee-modal-buttons .employee-pop-up-btn #submit-btn {
    color: black;
    font-weight: bold;
    font-size: 14px;
    font-weight: bold;
    font-family: var(--font);
    text-align: left;
    text-transform: uppercase;
    line-height: 20px;
    letter-spacing: 2.3px;
    background-color:  var(--green);
    border-color: var(--green);
    box-shadow: none;


    height: 52px;
    margin-left: 32px;
    padding: 16px 17.5px;
    border-radius: 4px;
}

.employee-modal-buttons .employee-pop-up-btn #submit-btn-disabled {
    color: var(--dark-grey3);
    font-weight: bold;
    font-size: 14px;
    font-weight: bold;
    font-family: var(--font);
    text-align: left;
    text-transform: uppercase;
    line-height: 20px;
    letter-spacing: 2.3px;
    background-color:  var(--light-grey);
    border-color: var(--light-grey);
    box-shadow: none;
    height: 52px;
    margin-left: 32px;
    padding: 16px 17.5px;
    border-radius: 4px;
}

.employee-modal-buttons #cancel-btn {
    color: black;
    font-weight: bold;
    font-size: 14px;
    font-weight: bold;
    font-family: var(--font);
    text-align: left;
    text-transform: uppercase;
    line-height: 20px;
    letter-spacing: 2.3px;
    background-color:  white;
    border-color: white;
    box-shadow: none;

    width: 105px;
    height: 52px;
    margin-left: 32px;
    padding: 16px 17.5px;
    border-radius: 4px;
}

.disabled-light {
    background-color: var(--light-grey);
    color: var(--lg-font);
}

.disabled-dark {
    background-color: var(--dark-grey);
    color: var(--dg-font);
}

.enabled{
    background-color: var(--green);
}

.add-employee-container .overlay {
    z-index: 100;
    position: absolute;
    width: 928px;
    height: 885px;

    opacity: 0.05;
    background-color: rgba(0, 0, 0, 0.449);
}

/* Confirmation Pop up */
.confirm-archive-popup .confirm-pop-up-btn-container {
    margin: 62px 35px 32px 0
}

.confirm-archive-popup .confirm-pop-up-btn-container .archivebutton {
    margin-left: 20px;
}

.confirm-archive-popup .modal-dialog {
    min-width: 928px; 
}

.confirm-archive-popup .modal-content {
    margin-top: 270px;
    width: 928px;
}

.confirm-archive-popup .confirm-archive-popup-container .text {
    margin-left: 20px;
}

.confirm-archive-popup .confirm-archive-popup-container p {
    margin-top: 27px;
    margin-left: 16px;
}

.confirm-archive-popup  #cancel-btn {
    color: black;
    font-weight: bold;
    font-size: 14px;
    font-weight: bold;
    font-family: var(--font);
    text-align: left;
    text-transform: uppercase;
    line-height: 20px;
    letter-spacing: 2.3px;
    background-color:  white;
    border-color: white;
    box-shadow: none;

    width: 105px;
    height: 52px;
    margin-left: 32px;
    padding: 16px 17.5px;
    border-radius: 4px;
}

/* email */
.add-employee-container .email-ending {
    margin-bottom: 14px;
    margin-left: 17px;
}

/* vacation description */
.add-employee-container .vacation-description {
    width: 380px;
    text-align: left;
    font-size: 14px;
    margin: 0 8px;
}

.add-employee-container .vacation-description-container {
    width: 436px;
}

/* checkboxes */
.add-employee-container .add-employee-checkbox-container {
    width: 436px;
}

/* for disabled inputs */
.add-employee-container .disabled-field {
    background-color: var(--off-white);
}

/* disabled */
.add-employee-container .disabled-header {
    font-size: 14px;
    font-weight: bold;
    font-family: var(--font);
    text-align: left;
    text-transform: uppercase;
    line-height: 20px;
    letter-spacing: 2.3px;
    margin: 30px 0px 5px 10px;
    color: var(--dark-grey3)
}

.add-employee-container .text-disabled {
    color: var(--dark-grey3)
}


.add-employee-container .modal-buttons .employee-pop-up-btn #submit-btn-disabled:hover {
    cursor: default;
}

