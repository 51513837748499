.archivebutton .button{
    padding: 16px 17.5px;
    border: 1px solid rgba(0, 0, 0, 0);
    border-radius: 4px;
    font-family: var(--font);
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43em;
    letter-spacing: 2.3px;
    text-align: center;
    color: white;
}

.archivebutton .disabled-light {
    background-color: var(--light-grey);
    color: var(--lg-font);
}

.archivebutton .disabled-dark {
    background-color: var(--dark-grey);
    color: var(--dg-font);
}

.archivebutton .enabled{
    background-color: #666;
}