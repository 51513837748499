:root {
   --font: 'Gotham A';
   --font-color: #000;
   --overlay-color: #000;
   --bg-color: #F2F2F2;
}

.calendar-date-picker .react-datepicker__day {
   width: 40px!important;
   height: 48px!important;
   border-radius: 4px;
   margin: 0.5px!important;
   background-color: inherit;
   border-radius: 4px!important;
   border: none;
   outline: none!important;
}

.calendar-date-picker .react-datepicker__day-names {
   display: none;
}

.calendar-date-picker .react-datepicker__day--selected, .react-datepicker__day--keyboard-selected {
   background-color: white!important;
}

.calendar-date-picker .react-datepicker__day:hover {
   border: none!important;
   box-shadow: none!important;
   background-color: white!important;
   outline: none!important;
}

.calendar-date-picker .react-datepicker__month {
   margin: 0!important;
   padding-top: 0.5px!important;
   padding-bottom: 0.5px!important;
   background-color: var(--bg-color);
   border-radius: 0 0 4px 4px;
}

.calendar-date-picker .react-datepicker__header {
   padding: 0!important;
   border: none;
}

.calendar-date-picker .react-datepicker {
   border: none!important;
}