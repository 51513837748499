.time-pg-popup .datepicker input {
    width: 135px;
}

.time-pg-popup .time-entry-field {
    width: 128px;
}

.time-pg-popup .project-dropdown {
    width: 537px;
}

.time-pg-popup .margin {
    margin: 0 16px;
}

.time-pg-popup .text-field-container {
    margin-top: 37px;
}

.time-pg-popup .text-field-container input {
    width: 864px;
}

.time-pg-popup .button-container {
    margin-top: 24px;
    margin-bottom: 32px;

    width: 864px;
}

.time-pg-popup #cancel-btn {
    font-weight: bold;
    font-size: 14px;
    font-family: var(--font);

    color: black;
    background-color: white;
    border-color: white;
    margin-right: 32px;

    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43em;
    letter-spacing: 2.3px;
    text-align: center;
    text-transform: uppercase;

}

.time-pg-margin-none {
    margin-top: 0;
}