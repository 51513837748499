:root {
  --bg-color-light: #f2f2f2;
  --bg-color-dark: #dbdbdb;
  --bg-color-active: #fff;
  --text-color-placeholder: rgba(0, 0, 0, 0.5);
  --hover-border-color: #1c2229;
  --active-border-color: #bababa;
  --font-color: #000;
}

.time-entry-field {
  font-family: 'Gotham A';
  font-size: 18px;
  line-height: 1.78;
  letter-spacing: 0.25px;
  color: var(--font-color);
  border-radius: 4px;
  background-color: var(--bg-color-light);
  border: none;
  /* Spec calls for a width of 128px, padding is 16px. 128px - 16px = 112px */
  /* width: 112px; */
  height: 52px;
  padding: 0px 0px 0px 16px;
  box-sizing: border-box;
  outline: none;
}

.time-entry-field::placeholder {
  color: var(--text-color-placeholder);
}

.time-entry-field:hover {
  border: solid 1px var(--hover-border-color);
  background-color: var(--bg-color-dark);
  /* Adjust padding so that the text doesn't move */
  padding: 0px 0px 0px 15px;
}

.time-entry-field:focus {
  padding: 0px 0px 0px 15px;
  border: solid 1px var(--active-border-color);
  background-color: var(--bg-color-active);
}

.time-entry-field-disabled {
  background-color: var(--off-white);
  color: var(--dark-grey3);
  opacity: 0.75;
}

.time-entry-field-disabled:hover {
  border: solid 1px var(--off-white);
  background-color: var(--off-white);
  /* Adjust padding so that the text doesn't move */
  padding: 0px 0px 0px 15px;
}