:root {
  --summary-font-color: black;
  --summary-border-color: rgba(186, 186, 186, 0.5);
}

.time-entry-cell > span {
  width: 65px;
  min-height: 32px;
  margin: 0 0 11px 16px;
  margin-right: var(--cell-margin);
  font-family: var(--font);
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.78;
  letter-spacing: 0.25px;
  color: var(--summary-font-color);
}

.time-entry-cell > p {
  text-align: left;
  min-height: 32px;
  width: 100%;
  margin: 0 0 11px 0;
  margin-right: var(--cell-margin);
  font-family: var(--font);
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.78;
  letter-spacing: 0.25px;
  color: var(--summary-font-color);
}