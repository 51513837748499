/* exclusively for lefthand drops logo*/
.logo-link {
    text-decoration: none;
    font-weight: 800;
    font-size: 1.25em;
}

.logo-link img {
    margin: 0 .25em;
}

.logo-link:hover {
    cursor: pointer;
}

/* for the rest of the navigation links*/
.nav-text {
    font-size: .75em;
    font-weight: bolder;
    line-height: 20px;
    letter-spacing: 2.3px;
}

#active {
    color: var(--green);
}

#inactive {
    color: black;
}

/* for temporary profile picture */
.profile-picture {
    width: 2.5em;
    border-radius: 50%;
}

.dropdown-toggle::after {
    display: none !important;
}