@import url(../../fonts/fonts.css);

.deletebutton button{
    background-color: #e02021;
    padding: 16px 15px 16px 18px;
    border: 1px solid #e02021;
    border-radius: 4px;
    color: white;
}

.deletebtn-contianer{
    display: flex;
    align-items: center;
    font-family: 'Gotham A';
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: 2.3px;
    text-align: center;
}

.deletebutton i{
    margin: 0 8px 1px 0
}