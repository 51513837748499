.add-btn-container {
  margin-top: 10px;
  margin-right: 15px;
}
.reports {
  display: flex;
  margin-bottom: 10vh;
  max-width: 100vw;
}

.reports .dropdown-projects .header {
  margin-top: 0px;
}

.reports .section-header {
  display: flex;
  /*margin-top: 46px;*/
  align-self: flex-start;
  width: 100%;
  max-width: 1320px;
  margin-left: auto;
  margin-right: auto;
}

.report-body {
  width: 80vw;
}

.section-header {
  margin-right: auto;
}
