.timeframe .component-header {
    width: 100%;
    text-align: left;
}

.timeframe .timeframe-option{
    margin-bottom: 24px;
}

/*
    customize radio button
*/

.timeframe-option input[type="radio"] {
    /* Add if not using autoprefixer */
    -webkit-appearance: none;
    appearance: none;
    /* For iOS < 15 to remove gradient background */
    background-color: #fff;
    /* Not removed via appearance */
    margin: 0;
  }

.timeframe-option input[type="radio"] {
    display: grid;
    place-content: center;

    appearance: none;
    background-color: #fff;
    margin: 16px;
    font: inherit;
    color: var(--lg-font);
    width: 20px;
    height: 20px;
    border: 2px solid var(--lg-font);
    border-radius: 50%;
}

.timeframe-option input[type="radio"]:checked {
    content: "";
    background-color: white;
    font: inherit;
    color: var(--green);
    width: 20px;
    height: 20px;
    border: 2px solid var(--green);
    border-radius: 50%;

    margin: 16px;
}

.timeframe-option input[type="radio"]:checked:after {
    content: "";
    background-color: var(--green);
    margin: 0;
    font: inherit;
    color: var(--green);
    width: 10px;
    height: 10px;
    border: 1px solid var(--green);
    border-radius: 50%;
    margin: 16px;
}
