/* project popup */
.project-popup .modal-dialog {
    min-width: 940px; 
}

.project-popup .modal-dialog .modal-content {
    margin-top: 270px;
    width: 940px; 
}

.button-container {
    margin-bottom: 35px;
}

.projects #addproject-btn{
    color: var(--green);
    font-weight: bold;
    font-family: var(--font);
    background-color:  white; 
    border-color: white;
    box-shadow: none;
}

.projects #archiveproject-btn{
    color: var(--green);
    font-weight: bold;
    font-family: var(--font);
    background-color:  white; 
    border-color: white;
    box-shadow: none;
}

.project-modal-buttons #submit-btn{
    color: black;
    font-weight: bold;
    font-size: 14px;
    font-weight: bold;
    font-family: var(--font);
    text-align: left;
    text-transform: uppercase;
    line-height: 20px;
    letter-spacing: 2.3px;
    background-color:  var(--green); 
    border-color: var(--green);
    box-shadow: none;

    height: 52px;
    margin: 24px 32px 32px 32px;
    padding: 16px 17.5px;
    border-radius: 4px;
}

.project-modal-buttons #cancel-btn{
    color: black;
    font-weight: bold;
    font-size: 14px;
    font-weight: bold;
    font-family: var(--font);
    text-align: left;
    text-transform: uppercase;
    line-height: 20px;
    letter-spacing: 2.3px;
    background-color:  white;
    border-color: white;
    box-shadow: none;

    width: 105px;
    height: 52px;
    margin: 32px;
    padding: 16px 17.5px;
    border-radius: 4px;
}

/* search bar*/
.projects .searchbar-container { /* container */
    width: 928px;

    padding: 5px 16px;
    border: 1px solid var(--light-grey2);
    border-radius: 4px;
    background-color: var(--light-grey2);
}

.projects .active { /* active container */
    border-color: var(--green);
}

.projects .input-searchbar { /* input search bar */
    border: none;
    background-color: var(--light-grey2);
    
    width: 875px;

    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.78em;
    letter-spacing: 0.25px;
    color: rgba(0, 0, 0, 0.5);
}

.input-searchbar:focus-visible { /* remove default styles */
    outline: none;
}

/* client popup */
.client-name-popup .modal-dialog {
    min-width: 928px; 
}

.client-name-popup .modal-content {
    width: 928px;
    margin-top: 270px;
}

.client-name-textfield {
    width: 860px;
}

.client-modal-buttons #client-cancel-btn{
    color: black;
    font-weight: bold;
    font-size: 14px;
    font-weight: bold;
    font-family: var(--font);
    text-align: left;
    text-transform: uppercase;
    line-height: 20px;
    letter-spacing: 2.3px;
    background-color:  white;
    border-color: white;
    box-shadow: none;

    width: 105px;
    height: 52px;
    margin: 32px;
    padding: 16px 17.5px;
    border-radius: 4px;
}

.client-modal-buttons #client-submit-btn{
    color: black;
    font-weight: bold;
    font-size: 14px;
    font-weight: bold;
    font-family: var(--font);
    text-align: left;
    text-transform: uppercase;
    line-height: 20px;
    letter-spacing: 2.3px;
    background-color:  var(--green);
    border-color: var(--green);
    box-shadow: none;

    height: 52px;
    margin: 32px;
    padding: 16px 17.5px;
    border-radius: 4px;
}

/* containers for projects by clients */
.archived-projects-container {
    width: 928px;
}

.breaks { /* hr */
    width: 928px;
    color: var(--light-grey4);
}

.client-name {
    font-family: var(--font);
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.67em;
    letter-spacing: 0.21px;
    color: #000;

    margin-top: 44px;
    margin-bottom: 28px;

}

.project-name {
    font-family: var(--font);
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.78;
    letter-spacing: 0.25px;
    color: #000;
}

/* general */
.margin {
    margin-left: 16px;
}