:root {
  --header-bg-color: #6cc72b;
  --font: 'Gotham A';
  --date-color: #000;
}

.calendar-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 288px;
  height: 80px;
  margin: 0;
  background-color: var(--header-bg-color);
  border-radius: 4px 4px 0px 0px;
}

.calendar-header-stepper {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  padding-top: 16px;
}

.next-btn {
  padding-right: 17px;
}

.previous-btn {
  padding-left: 17px;
}

.calendar-header-date {
  height: 16px;
  font-family: var(--font);
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 2.63px;
  text-align: center;
  color: var(--date-color);
}

.calendar-header-days {
  display: flex;
  justify-content: space-around;
  width: 100%;
  margin-bottom: 6px;
  font-family: var(--font);
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 2.63px;
  color: var(--date-color);
}

.calendar-header-days > span {
  width: 17px;
  height: 16px;
}
