.dayBtn{
    width: 58px;
    height: 52px;
    background-color: var(--light-grey2);
    color: #979797;

    font-size: 16px;
    font-weight: 500;
    letter-spacing: 2.63px;

    border: 0px solid var(--light-grey2);
    border-radius: 4px;
    margin: .5px;

    text-align: center;
}

.activeDayBtn{
    background-color: var(--green);
    color: black;
    margin: .5px;
}

.dayBtn-inactive {
    opacity: 75%;
    margin: .5px;
}