.textfield {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43px;
    letter-spacing: 2.3px;
}

.textfield label {
    margin: 1em;
}

.textfield input{
    padding: 10px;
    height: 52px;
}

.invalidText {
    color: var(--red);
}

.textfield .default {
    background-color: var(--light-grey2);
    border: 1px solid var(--light-grey2);
    border-radius: 4px;
}

.textfield .default:hover {
    background-color: var(--dark-grey2);
    border: solid 1px black;
    border-radius: 4px;
}

.textfield .default:focus-within {
    background-color: white;
    border: solid 1px var(--dark-grey2);
    caret-color: var(--green);
    outline: none;
}

.textfield .invalid {
    background-color: var(--light-grey2);
    border: 1px solid var(--red);
    border-radius: 4px;
}

.textfield .invalid:hover {
    background-color: var(--dark-grey2);
    border: 2px solid var(--red);
    border-radius: 4px;
}

.textfield .invalid:focus-within {
    background-color: white;
    border: solid 1px var(--red);
    caret-color: var(--red);
    outline: none;
}

.fc-feedback {
    font-family: 'Gotham A';
    font-size: .75em;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.86px;
    letter-spacing: 0.19px;
    color: var(--red);
    margin: 1em;
}

.textfield .disable{
    opacity: 0.75;
    background-color: var(--off-white);
    border: 1px solid #f2f2f200;
    border-radius: 4px;
}

.textfield-header-disabled {
    color: var(--dark-grey3);
}