.summary-ctn {
  max-width: 608px;
  width: 100%;
}

.summary-total {
  margin-top: -30px;;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.summary-total > span {
  margin-left: var(--cell-margin);
  font-family: var(--font);
  font-size: 18px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.78;
  letter-spacing: 0.25px;
}
