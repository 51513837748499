:root {
  --switch-color: #cc1e4a;
}

.form-switch-md.form-switch.form-check.switch > div {
  display: flex;
  flex-direction: row-reverse!important;
  align-items: center;
}

.form-switch-md.form-switch.form-check.switch > div > label {
  margin-right: 60px;
  font-family: var(--font);
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.78;
  letter-spacing: 0.25px;
}

.form-switch.form-switch-md .form-check-input {
  width: 40px;
  height: 22px;
  border-radius: 11px;
  background-color: var(--switch-color);
  border: none;
}

.form-switch.form-switch-md .form-check-input {
  background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27%23fff%27/%3e%3c/svg%3e");
  width: 40px;
  height: 20px;
}

.form-switch.form-switch-md .form-check-input:focus {
  box-shadow: none !important;
}

.active .form-switch.form-switch-md .form-check-input{
  background-color: var(--green);
}

.inactive .form-switch.form-switch-md .form-check-input{
  background-color: var(--switch-color);
}