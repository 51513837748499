.select{
    text-align: left;
    letter-spacing: 0.25px;
    color: #000;
    background-color: #f2f2f2;
    border-radius: 4px;
}

.dropdown-header-react-select{
    margin-left: 10px;
    margin-bottom: 11px;

    font-size: 14px;
    font-weight: bold;
    line-height: 20px;
    letter-spacing: 2.3px;

    color:black;
}

.archiveLabel{
    color: darkgray;
    font-style: italic;
    font-size: 12px;
}

.drop-down-disabled-label {
    color: var(--dark-grey3)
}
