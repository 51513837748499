input[type="time"]::-webkit-calendar-picker-indicator {
    background: none;
    display: none
}

.time-text-field input {
    border-radius: 4px;

    padding-left: 16px;

    height: 52px;
}

.time-text-field .enable {
    background-color: var(--light-grey2);
    border: 1px solid var(--light-grey2);
}

.time-text-field .disable {
    opacity: 0.75;
    background-color: var(--off-white);
    border: 1px solid #f2f2f200;
}