#checkbox {
    display: none;
}

#checkbox + span {
    display: inline-block;
    position: relative;
    text-align: center;
    vertical-align: center;
    top: -2px;
    width: 20px;
    height: 20px;
    margin: -1px 0px 0 0;
    vertical-align: middle;
    border: solid 1px #979797;
    border-radius: 4px;
    font-size: 11px;
    color: white;
    cursor: pointer;
    color: white;
}

#checkbox:checked + span {
    background: var(--green) -19px top no-repeat;
    border: solid 3px var(--green);
    border-radius: 4px;
    font-size: 11px;
    color: white;
}

#checkbox + span {
    margin: auto 16px;
    text-align: center;
    vertical-align: center;
}

/* disabled */
.checkbox-container-disabled input[type="checkbox"] + span{
    opacity: 75%;
    border: solid 2px var(--light-grey);
}
.checkbox-container-disabled input[type="checkbox"]:checked + span {
    background: var(--green) -19px top no-repeat;
    border: solid 3px var(--green);
    opacity: 50%;
}

.checkbox-container-disabled:hover {
    cursor: default;
}
