.datepicker-single{
    text-align: left;
}

.react-datepicker {
    border: 1px solid var(--light-grey3);
}

.datepicker input {
    height: 52px;

    padding: 10px;

    background-color: var(--light-grey2);
    border: 1px solid var(--light-grey2);
    border-radius: 4px;
}

.react-datepicker__header {
    text-transform: uppercase;
    background-color: var(--off-white);
}

.react-datepicker__day-name {
    font-size: 10px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 1.5px;
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
    border-radius: 50%;
    background-color: var(--green-opacity3);
    color: black;
}

.react-datepicker__day:hover,
.react-datepicker__month-text:hover,
.react-datepicker__quarter-text:hover,
.react-datepicker__year-text:hover {
    border-radius: 50%;
    background-color: var(--green-opacity3);
}

.react-datepicker__day--in-selecting-range:not(.react-datepicker__day--in-range,
    .react-datepicker__month-text--in-range,
    .react-datepicker__quarter-text--in-range,
    .react-datepicker__year-text--in-range),
.react-datepicker__month-text--in-selecting-range:not(.react-datepicker__day--in-range,
    .react-datepicker__month-text--in-range,
    .react-datepicker__quarter-text--in-range,
    .react-datepicker__year-text--in-range),
.react-datepicker__quarter-text--in-selecting-range:not(.react-datepicker__day--in-range,
    .react-datepicker__month-text--in-range,
    .react-datepicker__quarter-text--in-range,
    .react-datepicker__year-text--in-range),
.react-datepicker__year-text--in-selecting-range:not(.react-datepicker__day--in-range,
    .react-datepicker__month-text--in-range,
    .react-datepicker__quarter-text--in-range,
    .react-datepicker__year-text--in-range) {
    background-color: var(--green-opacity3);
    color: black;
}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
    font-family: var(--font);
    text-transform: uppercase;
    font-size: 1em;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 1.5px;
    text-align: center;
}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
    font-family: var(--font);
    text-transform: uppercase;
    margin-bottom: .75em;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
    border-radius: 50%;
    background-color: var(--green);
    color: black;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected {
    background-color: var(--green);
}

.react-datepicker__month--selected,
.react-datepicker__month--in-selecting-range,
.react-datepicker__month--in-range,
.react-datepicker__quarter--selected,
.react-datepicker__quarter--in-selecting-range,
.react-datepicker__quarter--in-range {
    border-radius: 0.3rem;
    background-color: var(--green);
    color: #fff;
}

.react-datepicker__month-text.react-datepicker__month--selected:hover,
.react-datepicker__month-text.react-datepicker__month--in-range:hover,
.react-datepicker__month-text.react-datepicker__quarter--selected:hover,
.react-datepicker__month-text.react-datepicker__quarter--in-range:hover,
.react-datepicker__quarter-text.react-datepicker__month--selected:hover,
.react-datepicker__quarter-text.react-datepicker__month--in-range:hover,
.react-datepicker__quarter-text.react-datepicker__quarter--selected:hover,
.react-datepicker__quarter-text.react-datepicker__quarter--in-range:hover {
    background-color: var(--green);
}

.react-datepicker__close-icon::after {
    cursor: pointer;
    background-color: var(--green);
    color: #fff;
    border-radius: 50%;
    height: 16px;
    width: 16px;
    padding: 2px;
    font-size: 12px;
    line-height: 1;
    text-align: center;
    display: table-cell;
    vertical-align: middle;
    content: "×";
}

.react-datepicker-popper[data-placement^=top],
.react-datepicker__triangle,
.react-datepicker-popper[data-placement^=bottom],
.react-datepicker__triangle {
    margin-left: 5px;
    position: absolute;
    width: 0;
}

.react-datepicker-popper[data-placement^=top],
.react-datepicker__triangle,
.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle {
    display: none;
}

.datepicker .disabled {
    background-color: var(--off-white);
    border: 1px solid var(--off-white);
    color: var(--dark-grey3);
    opacity: 0.75;
}