.logout{
    background-color: transparent;
    color: var(--green);
    border: none;

    margin-left: 27px;
    margin-top: 0;

    font-size: 14px;
    font-weight: bold;
    line-height: 20px;
    letter-spacing: 2.3px;
}

.google-logout .email {
    font-family: var(--font);
    font-weight: bold;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43em;
    letter-spacing: 2.3px;
    color: #000;
}