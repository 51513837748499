.addproject {
    width: 950px;
}

.addproject .button{
    padding: 16px 17.5px;
    border: 1px solid rgba(0, 0, 0, 0);
    border-radius: 4px;
    font-family: var(--font);
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: 2.3px;
    text-align: center;
}

.addproject .dropdown-client {
    min-width: 380px;
}

.addproject .textfield-client {
    min-width: 380px;
}

.addproject .project-name {
    min-width: 640px;
}

.addproject .textfield label {
    font-size: 14px;
}

.addproject .dropdown-employee{
    min-width: 864px;
}

.addproject .project-div {
    width: 100%;
}

.margin-sm{
    margin: 0 16px;
}

/* popup */
.add-project-modal-buttons {
    width: 864px;
    margin-left: 16px;
    margin-top: 40px;
    margin-bottom: 32px;
}

.add-project-modal-buttons #add-project-cancel-btn{
    color: black;
    font-weight: bold;
    font-size: 14px;
    font-weight: bold;
    font-family: var(--font);
    text-align: left;
    text-transform: uppercase;
    line-height: 20px;
    letter-spacing: 2.3px;
    background-color:  white; /* new color goes her */
    border-color: white;
    box-shadow: none;

    width: 105px;
    height: 52px;
    margin: 0 32px;
    padding: 16px 17.5px;
    border-radius: 4px;
}

.add-project-modal-buttons #add-project-submit-btn{
    color: black;
    font-weight: bold;
    font-size: 14px;
    font-weight: bold;
    font-family: var(--font);
    text-align: left;
    text-transform: uppercase;
    line-height: 20px;
    letter-spacing: 2.3px;
    background-color:  var(--green); /* new color goes her */
    border-color: var(--green);
    box-shadow: none;

    height: 52px;
    padding: 16px 17.5px;
    border-radius: 4px;
}

.add-project-modal-buttons #add-project-submit-btn-disabled {
    color: var(--dark-grey3);
    font-weight: bold;
    font-size: 14px;
    font-weight: bold;
    font-family: var(--font);
    text-align: left;
    text-transform: uppercase;
    line-height: 20px;
    letter-spacing: 2.3px;
    background-color:  var(--light-grey); 
    border-color: var(--light-grey);
    box-shadow: none;
    
    height: 52px;

    padding: 16px 17.5px;
    border-radius: 4px;
}

.addproject .timeframe .addproject .client-option{
    margin-bottom: 24px;
}

/*
    customize radio button
*/
.addproject .client-option-text {
    margin-left: 15px
}

.addproject .client-option input[type="radio"] {
    /* Add if not using autoprefixer */
    -webkit-appearance: none;
    appearance: none;
    /* For iOS < 15 to remove gradient background */
    background-color: #fff;
    /* Not removed via appearance */
    margin: 0;
  }

.addproject .client-option input[type="radio"] {
    display: grid;
    place-content: center;

    appearance: none;
    background-color: #fff;
    margin: 16px;
    font: inherit;
    color: var(--lg-font);
    width: 20px;
    height: 20px;
    border: 2px solid var(--lg-font);
    border-radius: 50%;
}

.addproject .client-option input[type="radio"]:checked {
    content: "";
    background-color: white;
    font: inherit;
    color: var(--green);
    width: 20px;
    height: 20px;
    border: 2px solid var(--green);
    border-radius: 50%;

    margin: 16px;
}

.addproject .client-option input[type="radio"]:checked:after {
    content: "";
    background-color: var(--green);
    margin: 0;
    font: inherit;
    color: var(--green);
    width: 10px;
    height: 10px;
    border: 1px solid var(--green);
    border-radius: 50%;
    margin: 16px;
}
